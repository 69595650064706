/**
 * @fileOverview
 * This module defines message enums
 * 
 * The enums provide a centralized place for managing the status messages
 * returned during the user authentication flow. This ensures consistency
 * in messaging throughout the application and makes it easier to manage
 * and update messages in the future.
 * 
 * - `SignupMessage`: Contains messages related to the signup process.
 * - `LoginMessage`: Contains messages related to the login process.
 * 
 */

export enum SignupMessage {
    CREATED = "Successfully created user.",
    CONFLICT_PHONE_NUMBER =  "Phone verified with another user already.",
    CONFLICT_USERNAME = "Username already in use",
    SERVER_ERROR = "Server error. Please try again later.",
    UNEXPECTED_ERROR = "An unexpected error occurred. Please try again."
}


export enum LoginMessage {
    SUCCESS = "Successfully sent login token",
    INVALID_CREDENTIALS = "Invalid username or password.",
    SERVER_ERROR = "Server error. Please try again later.",
    UNEXPECTED_ERROR = "An unexpected error occurred. Please try again."    
}