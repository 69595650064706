interface LegalItem {
  title: string;
  text: string;
}

export const termsOfServiceItems: LegalItem[] = [
  {
    title: "1. Acceptance of Terms",
    text: 'Welcome to Agnsy LLC ("Company," "we," "us," or "our"). By accessing or using our website, products, and services (collectively, the "Services"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, do not use our Services.',
  },
  {
    title: "2. Description of Services",
    text: 'Agnsy LLC provides AI-driven technology solutions for the real estate industry, including but not limited to property analysis, market predictions, automated disclosure analysis, and agent tools ("Services"). Our Services are designed to assist real estate professionals and clients in making informed decisions. ',
  },
  {
    title: "3. Eligibility",
    text: "You must be at least 18 years old to use our Services. By using our Services, you represent and warrant that you meet this eligibility requirement.",
  },
  {
    title: "4. User Accounts",
    text: "To access certain features of our Services, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information as necessary. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.",
  },
  {
    title: "5. License to Use Services",
    text: "Subject to your compliance with these Terms, Agnsy LLC grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Services for your personal or professional use.",
  },
  {
    title: "6. Automated Disclosure Analysis and User Responsibility",
    text: "Our Services may include automated disclosure analysis of properties to identify potential issues or provide summaries of disclosures. While our AI tools are designed to enhance efficiency and accuracy, they are not a substitute for thorough manual review by qualified professionals. You acknowledge and agree that it is your responsibility to manually review all property disclosures and other relevant documents. Agnsy LLC shall not be held liable for any errors, omissions, or discrepancies in the automated analysis that result from the absence of such a manual review.",
  },
  {
    title: "7. Prohibited Activities",
    text: "You agree not to:\n\n• Use the Services for any unlawful purpose.\n• Interfere with or disrupt the Services or servers or networks connected to the Services.\n• Attempt to gain unauthorized access to any part of the Services or to any other accounts, computer systems, or networks connected to the Services.\n• Abuse free trial offerings by creating more than one account.",
  },
  {
    title: "8. Intellectual Property",
    text: "All content, features, and functionality of the Services, including but not limited to text, graphics, logos, and software, are the exclusive property of Agnsy LLC and are protected by United States and international copyright, trademark, and other intellectual property laws.",
  },
  {
    title: "9. Privacy",
    text: "Your use of the Services is also governed by our Privacy Policy, which is incorporated by reference into these Terms. Please review our Privacy Policy to understand our practices.",
  },
  {
    title: "10. Disclaimers",
    text: 'The Services are provided on an "as-is" and "as-available" basis. Agnsy LLC makes no representations or warranties of any kind, express or implied, as to the operation of the Services or the information, content, or materials included on the Services.',
  },
  {
    title: "11. Limitation of Liability",
    text: "To the fullest extent permitted by law, Agnsy LLC shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of or inability to use the Services.",
  },
  {
    title: "12. Indemnification",
    text: "You agree to indemnify, defend, and hold harmless Agnsy LLC, its officers, directors, employees, and agents, from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your access to or use of the Services.",
  },
  {
    title: "13. Governing Law",
    text: "These Terms shall be governed by and construed in accordance with the laws of the State of California, without regard to its conflict of law principles. Any legal action or proceeding arising under these Terms will be brought exclusively in the federal or state courts located in [Your County], California, and you hereby consent to the personal jurisdiction and venue therein.",
  },
  {
    title: "14. Changes to Terms",
    text: "We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on our website. You are advised to review these Terms periodically for any changes. Your continued use of the Services after any changes to the Terms constitutes your acceptance of the new Terms.",
  },
  {
    title: "15. Termination",
    text: "We may terminate or suspend your access to the Services at any time, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.",
  },
  {
    title: "16. Contact Information",
    text: "If you have any questions about these Terms, please contact us at:\n\nAgnsy LLC\n12333 Saratoga-Sunnyvale Rd #M\nSaratoga, CA 95070\nteam@agnsy.ai",
  },
];

export const privacyPolicyItems: LegalItem[] = [
  {
    title: "1. Introduction",
    text: 'Agnsy LLC ("Company," "we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services, including our website and AI-driven real estate technology solutions ("Services"). By accessing or using our Services, you agree to this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not access or use our Services.',
  },
  {
    title: "2. Information We Collect",
    text: "We collect several types of information from and about users of our Services, including:\n\n• Personal Information: Information by which you may be personally identified, such as your name, email address, phone number, and other contact details.\n•Property Information: Information related to the properties analyzed by our AI tools, including property addresses, disclosures, and other relevant real estate data.\n•Usage Data: Information about your interactions with our Services, such as the pages you visit, the features you use, and the time you spend on our platform.\n•Technical Data: Information about your device and internet connection, including your IP address, browser type, and operating system.",
  },
  {
    title: "3. How We Collect Information",
    text: "We collect information in various ways, including:\n\n•Directly from You: When you register for an account, fill out forms on our website, or otherwise interact with our Services.\n•Automatically: Through the use of cookies, web beacons, and other tracking technologies that collect information about your interactions with our Services.\n•From Third Parties: We may receive information about you from other sources, such as public databases, our business partners, or social media platforms.",
  },
  {
    title: "4. How We Use Your Information",
    text: "We use the information we collect for various purposes, including:\n\n•To Provide and Improve Our Services: We use your information to operate, maintain, and enhance the features of our Services.\n•To Communicate with You: We may use your contact information to respond to your inquiries, send you updates, and provide you with information about our Services.\n•To Personalize Your Experience: We may use your information to tailor the content and features of our Services to your preferences.\n•To Analyze and Understand Usage: We use data analytics to monitor and analyze trends, usage, and activities in connection with our Services.\n•To Comply with Legal Obligations: We may use your information to comply with applicable laws, regulations, and legal processes.",
  },
  {
    title: "5. Disclosure of Your Information",
    text: "We may share your information in the following circumstances:\n\n•With Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as hosting, data analysis, and customer support.\n•For Legal Reasons: We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).\n•In Connection with Business Transfers: If we undergo a merger, acquisition, or asset sale, your information may be transferred as part of that transaction.\n•With Your Consent: We may share your information with your consent or at your direction.",
  },
  {
    title: "6. Data Security",
    text: "We implement reasonable administrative, technical, and physical safeguards designed to protect your information from unauthorized access, use, alteration, and disclosure. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.",
  },
  {
    title: "7. Your Privacy Rights",
    text: "Depending on your location, you may have certain rights regarding your personal information. In California, these rights include:\n\n•Right to Know: You have the right to request that we disclose the categories and specific pieces of personal information we have collected about you.\n•Right to Delete: You have the right to request that we delete any personal information we have collected from you, subject to certain exceptions.\n•Right to Opt-Out: You have the right to opt-out of the sale of your personal information, if applicable.",
  },
  {
    title:
      "To exercise any of these rights, please contact us using the information provided below.",
    text: "",
  },
  {
    title: "8. Cookies and Tracking Technologies",
    text: "We use cookies and similar tracking technologies to collect information about your interactions with our Services. You can control the use of cookies through your browser settings, but please note that disabling cookies may affect your experience on our website.",
  },
  {
    title: "9. Third-Party Links",
    text: "Our Services may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review the privacy policies of any third-party sites you visit.",
  },
  {
    title: "10. Changes to This Privacy Policy",
    text: 'We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any material changes by posting the new Privacy Policy on our website and updating the "Effective Date" at the top of this page.',
  },
  {
    title: "16. Contact Information",
    text: "If you have any questions about these Terms, please contact us at:\n\nAgnsy LLC\n12333 Saratoga-Sunnyvale Rd #M\nSaratoga, CA 95070\nteam@agnsy.ai",
  },
];
