import {
  AuthStatusCode,
  SignupStatusCodes,
  LoginStatusCodes,
} from "./accountStatusCodes";
import { logInCustomToken } from "../../../../firebase/authentication/functions";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

// ============================
// Common Account Flow Functions
// ============================

/**
 * Sends a signup request to the server and handles the response.
 *
 * @param {FormData} formData - The data for the new user account.
 * @returns {Promise<SignupStatusCodes>} - The status code indicating the result of the signup process.
 */
export async function UserSignup(
  formData: FormData
): Promise<SignupStatusCodes> {
  // Configuration options for the fetch request
  const requestOptions = {
    method: "POST",
    body: formData,
  };

  try {
    console.log("Sending signup request..."); // Log request
    // send the signup request to the API
    const response = await fetch(
      `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/signup`,
      requestOptions
    );

    if (response.ok) {
      console.log("Signup successful!");
      return SignupStatusCodes.CREATED;
    }

    const errorData = await response.json();

    if (response.status === AuthStatusCode.CONFLICT) {
      // Handle specific conflict errors based on response message
      const detail = errorData.detail?.trim().toLowerCase();

      // Username conflict
      if (detail?.includes("username")) {
        console.log("Username conflict");
        return SignupStatusCodes.CONFLICT_USERNAME;
      }

      // Phone number conflict
      if (detail?.includes("phone")) {
        console.log("Phone number conflict");
        return SignupStatusCodes.CONFLICT_PHONE_NUMBER;
      }
    }

    if (response.status === AuthStatusCode.SERVER_ERROR) {
      console.log("Server error occured during signup.");
      return SignupStatusCodes.SERVER_ERROR;
    }
  } catch (error) {
    // Catch any unexpected errors during request
    console.error("Error during signup:", error);
    return SignupStatusCodes.UNEXPECTED_ERROR;
  }
  // Fallback for any unhandled errors
  return SignupStatusCodes.UNEXPECTED_ERROR;
}

/**
 * Sends a login request to the server and processes the response.
 *
 * @param {FormData} formData - The data for user login.
 * @returns {Promise<LoginStatusCodes>} - The status code indicating the result of the login process.
 */
export async function UserLogin(formData: FormData): Promise<LoginStatusCodes> {
  // Configuration options for the fetch request
  const requestOptions = {
    method: "POST",
    body: formData,
  };

  try {
    console.log("Sending login request...");
    // send the login request to the API
    const response = await fetch(
      `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/request-login-token`,
      requestOptions
    );

    if (response.ok) {
      // Parse response to extract token
      const tokenData = await response.json();
      const customToken = tokenData.token;

      // Use the custom token to sign in
      await logInCustomToken(customToken);

      console.log("Login successful!");
      return LoginStatusCodes.SUCCESS;
    }

    // Handle for invalid credentials
    if (response.status === AuthStatusCode.BAD_REQUEST) {
      console.log("Invalid credentials provided.");
      return LoginStatusCodes.INVALID_CREDENTIALS;
    }
  } catch (error) {
    // Catch any unexpected errors during request
    console.error("Error during token retrievel: ", error);
    return LoginStatusCodes.UNEXPECTED_ERROR;
  }
  // Fallback for any unhandled errors
  return LoginStatusCodes.UNEXPECTED_ERROR;
}
