/* React */
import { useEffect, useState } from "react";

/* Data Models */
import { ClassifiedFile } from "../../../../../../../model/DisclosureAnalysis/ClassifiedFile";
import { PDFFile } from "../../../../../../../model/DisclosureAnalysis/PDFFile";
import {
  ComponentAnalysisSubtype,
  ComponentSubtypeDisplayMapping,
} from "../../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/Types";

/* Functions and Utils */
import PDFCache from "../../../../../../../utils/cache/pdf";
import { fetchPdfFile } from "../../../../api";

/* Styling */
import "./CorrespondingFiles.css";

interface CorrespondingFilesProps {
  files: ClassifiedFile[];
  pdfCache: PDFCache | null;
}

export default function CorrespondingFiles({
  files,
  pdfCache,
}: CorrespondingFilesProps): JSX.Element {
  const [pdfFiles, setPDFFiles] = useState<PDFFile[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  /**
   * API Calls
   */
  useEffect(() => {
    const fetchPDFs = async () => {
      if (files !== undefined && files.length > 0 && pdfCache) {
        try {
          const newPdfFiles = await Promise.all(
            files.map(async (classifiedFile) => {
              const { pdf } = classifiedFile;
              try {
                const cachedPDF = await pdfCache.get(pdf.file_id);
                if (cachedPDF) {
                  return cachedPDF;
                } else {
                  // Fetch the PDF if it's not in the cache
                  const fetchedPDF = await fetchPdfFile(pdf);
                  pdfCache.store(fetchedPDF, pdf.file_id);
                  return fetchedPDF;
                }
              } catch (error) {
                console.error("Error fetching PDF CORRESPONDING FILES", error);
                return null;
              }
            })
          );

          const filteredPdfFiles = newPdfFiles.filter(
            (pdfFile) => pdfFile !== null
          ) as PDFFile[];
          setPDFFiles(filteredPdfFiles);
        } catch (error) {
          console.error("Error fetching PDF CORRESPONDING FILES", error);
        }
      }
    };

    fetchPDFs();

    // Cleanup function
    return () => {
      setPDFFiles([]);
    };
  }, [files, pdfCache]);

  return (
    <div className="corresponding-files">
      <button
        onClick={toggleDropdown}
        className={`corresponding-files-toggle-button ${isOpen ? "open" : ""}`}
      >
        <span className="corresponding-files-toggle-button-text">
          Corresponding Files
        </span>
        <svg
          className={`corresponding-files-toggle-arrow ${isOpen ? "open" : ""}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="5 8 14 10"
          width="14"
          height="14"
        >
          <path
            fill="white"
            d="M7.41 16.58L12 12l4.59 4.59L18 15.17l-6-6-6 6z"
          />
        </svg>
      </button>
      <div className={`corresponding-files-dropdown ${isOpen ? "open" : ""}`}>
        {pdfFiles.map((file, index) => (
          <div key={`${file.fileName}-${index}`}>
            <div key={file.fileName} className="corresponding-files-file-item">
              {files[index]?.subtype !== null &&
              files[index]?.subtype !== undefined ? (
                <span style={{ fontWeight: "500" }}>
                  {
                    ComponentSubtypeDisplayMapping[
                      files[index].subtype as ComponentAnalysisSubtype
                    ]
                  }
                  : &nbsp;
                </span>
              ) : (
                <></>
              )}
              {file.fileName}
            </div>
            <div className="corresponding-files-file-item-divider" />
          </div>
        ))}
      </div>
    </div>
  );
}
