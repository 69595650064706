import { SellerQuestionnaireAnalysisType } from "./SellerQuestionnaire/SellerQuestionnaireAnalysis";
import { TransferDisclosureAnalysisType } from "./TransferDisclosure/TransferDisclosureAnalysis";

enum ComponentAnalysisType {
  TRANSFERDISCLOSURE = "TRANSFERDISCLOSURE",
  SELLERQUESTIONNAIRE = "SELLERQUESTIONNAIRE",
  ESD = "ESD",
  GENERALINSPECTION = "GENERALINSPECTION",
  TERMITEINSPECTION = "TERMITEINSPECTION",
  HOAFINANCIALS = "HOAFINANCIALS",
  HOARENTALRESTRICTIONS = "HOARENTALRESTRICTIONS",
  NHD = "NHD",
  PRELIMTITLEREPORT = "PRELIMTITLEREPORT",
  ROOFINSPECTION = "ROOFINSPECTION",
  FOUNDATIONREPORT = "FOUNDATIONREPORT",
  POOLINSPECTION = "POOLINSPECTION",
  CUSTOM = "CUSTOM_COMPONENT",
}

export type ComponentAnalysisSubtype =
  | SellerQuestionnaireAnalysisType
  | TransferDisclosureAnalysisType;

export const ComponentTypeDisplayMapping: {
  [key in ComponentAnalysisType]: string;
} = {
  [ComponentAnalysisType.TRANSFERDISCLOSURE]: "Transfer Disclosure",
  [ComponentAnalysisType.SELLERQUESTIONNAIRE]: "Seller Questionnaire",
  [ComponentAnalysisType.ESD]: "ESD",
  [ComponentAnalysisType.GENERALINSPECTION]: "General Inspection",
  [ComponentAnalysisType.TERMITEINSPECTION]: "Termite Inspection",
  [ComponentAnalysisType.HOAFINANCIALS]: "HOA Financials",
  [ComponentAnalysisType.HOARENTALRESTRICTIONS]: "HOA Rental Restrictions",
  [ComponentAnalysisType.NHD]: "NHD",
  [ComponentAnalysisType.PRELIMTITLEREPORT]: "Preliminary Report",
  [ComponentAnalysisType.ROOFINSPECTION]: "Roof Inspection",
  [ComponentAnalysisType.FOUNDATIONREPORT]: "Foundation Report",
  [ComponentAnalysisType.POOLINSPECTION]: "Pool Inspection",
  [ComponentAnalysisType.CUSTOM]: "Custom",
};

export const ComponentSubtypeDisplayMapping: {
  [key in ComponentAnalysisSubtype]: string;
} = {
  // Transfer Disclosure
  [TransferDisclosureAnalysisType.TRANSFERDISCLOSUREDEFAULT]:
    "Transfer Disclosure",
  [TransferDisclosureAnalysisType.PRDS_TDS_0520]: "PRDS TDS 05/20",
  [TransferDisclosureAnalysisType.CAR_TDS_0623]: "CAR TDS 06/23",

  // Seller Questionnaire
  [SellerQuestionnaireAnalysisType.CAR_SPQ_0623]: "CAR SPQ 06/23",
  [SellerQuestionnaireAnalysisType.PRDS_SSC_1122]: "PRDS SSC 1122",
  [SellerQuestionnaireAnalysisType.SELLERQUESTIONNAIREDEFAULT]:
    "Seller Questionnaire",

  // Both
  [TransferDisclosureAnalysisType.ADDENDUM]: "Addendum",
};

export const ComponentCategories: {
  [category: string]: ComponentAnalysisType[];
} = {
  SellerDisclosures: [
    ComponentAnalysisType.TRANSFERDISCLOSURE,
    ComponentAnalysisType.SELLERQUESTIONNAIRE,
    ComponentAnalysisType.ESD,
  ],
  Inspections: [
    ComponentAnalysisType.GENERALINSPECTION,
    ComponentAnalysisType.TERMITEINSPECTION,
    ComponentAnalysisType.ROOFINSPECTION,
    ComponentAnalysisType.FOUNDATIONREPORT,
    ComponentAnalysisType.POOLINSPECTION,
  ],
  HOA: [
    ComponentAnalysisType.HOARENTALRESTRICTIONS,
    ComponentAnalysisType.HOAFINANCIALS,
  ],
  Other: [ComponentAnalysisType.PRELIMTITLEREPORT, ComponentAnalysisType.NHD],
};

export const ComponentCategoryDisplayMapping: {
  [key: string]: string;
} = {
  SellerDisclosures: "Seller Disclosures",
  Inspections: "Inspections",
  HOA: "HOA",
  Other: "Other",
};

export const MasterReportSectionCategories: {
  [category: string]: string[];
} = {
  SellerDisclosures: ["Seller Questionnaire", "Transfer Disclosure", "ESD"],
  Inspections: [
    "Roof Inspection",
    "Termite Inspection",
    "Pool Inspection",
    "Foundation Report",
  ],
  HOA: ["HOA Financials", "HOA Rental Restrictions"],
  Other: ["Preliminary Report", "NHD"],
  Custom: [],
};

export default ComponentAnalysisType;
