/* Data Models */
import {
  ComponentAnalysisModule,
  ComponentAnalysisStatus,
} from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/ComponentAnalysisModule";
import { ComponentTypeDisplayMapping } from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/Types";
import { SelectedPage } from "../../PDFViewer/PDFViewer";

/* UI Components */
import { ComponentAnalysisTitle, CorrespondingFiles } from "../Common/";
import { ComponentViewerSelector } from "./ComponentViewerSelector";

/* Functions and Utils */
import PDFCache from "../../../../../../utils/cache/pdf";
import { useEffect, useState } from "react";
import { DisclosurePackage } from "../../../../../../model/DisclosureAnalysis/DisclosurePackage";
import { Reference } from "../../../../../../model/DisclosureAnalysis/Reference/Reference";
import ChatView from "../Chat/ChatView";
import {
  ChatMessage,
  ChatRole,
  FirestoreChatRecord,
} from "../../../../../../model/DisclosureAnalysis/Chat/Chat";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

/**
 * PROPS: for the ComponentModuleViewer component.
 */
export interface ComponentModuleViewerProps {
  disclosurePackage: DisclosurePackage;
  componentAnalysisModule: ComponentAnalysisModule;
  pdfCache: PDFCache | null;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}

/**
 * COMPONENT: displays the analysis of a component based on its status.
 */
export default function ComponentModuleViewer({
  disclosurePackage,
  componentAnalysisModule,
  pdfCache,
  setSelectedPage,
}: ComponentModuleViewerProps): JSX.Element {
  const [chatRecord, setChatRecord] = useState<FirestoreChatRecord>({
    messages: [],
  });
  const [isTyping, setIsTyping] = useState(false);
  const [status, setStatus] = useState(componentAnalysisModule.status);

  const handleSendMessage = async (message: string) => {
    setIsTyping(true);
    const userMessage: ChatMessage = {
      role: ChatRole.USER,
      content: message,
      reference: null,
    };
    setChatRecord((prev) => ({ messages: [...prev.messages, userMessage] }));

    if (!componentAnalysisModule.id || !disclosurePackage.id) {
      console.error("Missing required data");
      setIsTyping(false);
      return;
    }

    const payload = {
      question: message,
      pdf_references:
        componentAnalysisModule.associated_files.map((file) => ({
          uid: disclosurePackage.uid,
          disclosure_id: disclosurePackage.id,
          file_id: file.pdf.file_id,
        })) || [],
      component_id: componentAnalysisModule.id,
      disclosure_id: disclosurePackage.id,
      user_id: disclosurePackage.uid,
    };

    try {
      const response = await fetch(
        `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/chat/message`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );

      if (response.status == 403) {
        const subscriptionErrorMessage: ChatMessage = {
          role: ChatRole.SYSTEM,
          content: "Document Chat is not available for this plan tier",
          reference: null,
        }
        setChatRecord((prev) => ({ messages: [...prev.messages, subscriptionErrorMessage] }));
      }

      if (response.ok) {
        const aiMessage: ChatMessage = await response.json();
        setChatRecord((prev) => ({ messages: [...prev.messages, aiMessage] }));
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsTyping(false);
    }
  };

  useEffect(() => {
    if (componentAnalysisModule) {
      setChatRecord(componentAnalysisModule.associated_chat);
    }
  }, [componentAnalysisModule]);

  useEffect(() => {
    setStatus(componentAnalysisModule.status);
  }, [componentAnalysisModule.status]);

  const onReferenceClick = (reference: Reference) => {
    if (reference.page != null && reference.pdf) {
      setSelectedPage({ page: reference.page, fileID: reference.pdf?.file_id });
    }
  };

  // Display component analysis based on stats
  switch (status) {
    case ComponentAnalysisStatus.UNINITIATED:
      return (
        <div className="analysis-module">
          <div className="component-wrapper">
            <ComponentAnalysisTitle type={componentAnalysisModule.type} />
            Uninitiated.
          </div>
        </div>
      );
    case ComponentAnalysisStatus.FILECLASSIFYING:
      return (
        <div className="analysis-module-fileclassifying">Detecting Files</div>
      );
    case ComponentAnalysisStatus.ANALYZING:
      return <div className="analysis-module-analyzing">Analyzing</div>;
    case ComponentAnalysisStatus.FILES_NOT_DETECTED:
      return (
        <div className="analysis-module files-not-detected">
          <div className="component-wrapper">
            <ComponentAnalysisTitle type={componentAnalysisModule.type} />
            No files relating to{" "}
            {ComponentTypeDisplayMapping[componentAnalysisModule.type]} in this
            package.
          </div>
        </div>
      );
    case ComponentAnalysisStatus.COMPLETE:
      return (
        <div className="analysis-module">
          <div className="component-wrapper">
            <ComponentAnalysisTitle type={componentAnalysisModule.type} />
            <CorrespondingFiles
              files={componentAnalysisModule.associated_files}
              pdfCache={pdfCache}
            />
            <ComponentViewerSelector
              component={componentAnalysisModule}
              setSelectedPage={setSelectedPage}
            />
          </div>

          <ChatView
            chatRecord={chatRecord}
            onSendMessage={handleSendMessage}
            onReferenceClick={onReferenceClick}
            componentType={componentAnalysisModule.type}
            isTyping={isTyping}
          />
        </div>
      );
    case ComponentAnalysisStatus.ERROR:
      return (
        <div className="analysis-module">
          <div className="component-wrapper">
            <ComponentAnalysisTitle type={componentAnalysisModule.type} />
            Error performing analysis.
          </div>
        </div>
      );
    default:
      return <div>This should not show</div>;
  }
}
