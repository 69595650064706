/* React */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

/* UI Components */
import Navbar from "../../../Common/Navbar";

/* Styling */
import "../auth.css";
import "./VerifyPhoneVerificationCode.css";
import { postWithAuth } from "../../../../firebase/authentication/auth";
import Spinner from "../../../Common/Spinner/LoadingSpinner";
import ErrorCircle from "../../../../assets/error_circle.svg";

/* Code*/
import {
  sendVerifyAccountCode,
  sendForgotPasswordCode,
  sendForgotUsernameCode,
} from "../SendPhoneVerificationCode/SendPhoneVerificationCode";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

enum TwilioVerificationStatus {
  PENDING = "pending",
  APPROVED = "approved",
  CANCELED = "canceled",
  MAX_ATTEMPTS_REACHED = "max_attempts_reached",
  DELETED = "deleted",
  FAILED = "failed",
  EXPIRED = "expired",
}

const VerifyPhone: React.FC = () => {
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const action = searchParams.get("action");
  const phone = searchParams.get("phone");

  const navigate = useNavigate();

  useEffect(() => {
    if (!phone) {
      navigate("/404");
    }

    const verifyPhone = async () => {
      if (phone && action === "account-verify") {
        const tempMessage = await sendVerifyAccountCode(phone);
        if (tempMessage === "ok") {
          setMessage("Message successfully sent.");
        }
      }
    };

    verifyPhone();
  }, [phone, navigate, action]);

  const handleChange = (index: number, value: string) => {
    if (code.some((digit) => digit === "")) {
      setMessage("");
    }
    if (value.length <= 1) {
      const newCode = [...code];
      newCode[index] = value.replace(/\D/g, "");
      setCode(newCode);

      if (value && index < 5) {
        const nextInput = document.getElementById(`input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const disableSubmission = code.some((digit) => digit === "");

  // TODO: Create a function to reuse the send for more usability code.
  const handleResendCode = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (!phone) {
      return;
    }
    let tempMessage;
    try {
      switch (action) {
        case "account-verify":
          tempMessage = await sendVerifyAccountCode(phone);
          break;
        case "forgot-password":
          tempMessage = await sendForgotPasswordCode(phone);
          break;
        case "forgot-username":
          tempMessage = await sendForgotUsernameCode(phone);
          break;
        default:
          setMessage("Error. Please try again");
      }

      if (tempMessage === "ok") {
        setMessage("Message successfully resent.");
      }
    } catch (error) {
      console.error("Error sending verification code:", error);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    const stringCode = code.join("");
    formData.append("code", code.join(""));

    try {
      switch (action) {
        case "account-verify":
          const verifyResponse = await VerifyAccountWithCode(stringCode);
          if (verifyResponse === "ok") {
            navigate("/disclosure/upload");
          } else {
            setMessage(verifyResponse);
          }
          break;
        case "forgot-username":
          if (phone) {
            const usernameResponse = await VerifyForgotUsernameCode(
              stringCode,
              phone
            );
            if (usernameResponse.message === "ok") {
              navigate(
                `/forgot-username?code=${encodeURIComponent(
                  usernameResponse.auth_token
                )}`
              );
            } else {
              setMessage(usernameResponse.message);
            }
          } else {
            setMessage("No phone number given");
          }
          break;

        case "forgot-password":
          if (phone) {
            const usernamePassword = await VerifyForgotPasswordCode(
              stringCode,
              phone
            );
            if (usernamePassword.message === "ok") {
              navigate(
                `/forgot-password?code=${encodeURIComponent(
                  usernamePassword.auth_token
                )}`
              );
            } else {
              setMessage(usernamePassword.message);
            }
          } else {
            setMessage("No phone number given");
          }
          break;

        default:
          setMessage("Error. Please try again");
      }
    } catch (error) {
      console.error("Error during verification:", error);
      setMessage("An error occurred during verification. Please try again.");
    }

    setIsLoading(false);
  };

  return (
    <div>
      <Navbar />
      <div className="auth auth--phone-verification">
        <h2 className="auth__title auth__title--phone-verification">
          Phone Verification
        </h2>

        <form className="auth__form auth__form--phone-verification">
          <div className="phone-verification__instruction">
            Enter your six-digit code here
          </div>

          <div className="phone-verification__message">
            A verification code has been sent to:
            <div className="phone-verification__phone-number">{phone}</div>
          </div>

          <div className="phone-verification__code-inputs">
            {code.map((digit, index) => (
              <input
                key={index}
                id={`input-${index}`}
                type="text"
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                maxLength={1}
                className="phone-verification__code-input"
              />
            ))}
          </div>

          <div className="auth__error-message auth__error-message--phone-verification">
            {message && (
              <p
                className={`auth__alert ${
                  message === "Message successfully resent." ||
                  message === "Message successfully sent."
                    ? "auth__alert--phone-resent-success"
                    : "auth__alert--phone-verification-error"
                }`}
              >
                {message !== "Message successfully resent." &&
                  message !== "Message successfully sent." && (
                    <img
                      src={ErrorCircle}
                      alt="Error Icon"
                      className="auth__error-icon auth__error-icon--phone-verification"
                    />
                  )}
                {message}
              </p>
            )}
          </div>

          <div className="auth__controls auth__controls--phone-verification">
            {isLoading ? (
              <div className="loading-spinner loading-spinner--small">
                <Spinner />
              </div>
            ) : (
              <button
                onClick={handleSubmit}
                className={`auth__button auth__botton--phone-verification ${
                  disableSubmission ? "auth__button--disabled" : ""
                }`}
                disabled={disableSubmission}
              >
                Verify
              </button>
            )}
          </div>

          <div className="auth__links auth__links--phone-verification">
            Didn’t receive the code?{" "}
            <button
              onClick={handleResendCode}
              className="auth__action-link auth__action-link--resend-code"
            >
              Resend Code
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

async function VerifyAccountWithCode(code: string): Promise<string> {
  const formData = new FormData();
  formData.append("code", code);
  // const navigate = useNavigate();

  try {
    const response = await postWithAuth(
      `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/phone/verify-code`,
      formData
    );

    if (response.ok) {
      const data = await response.json();
      const verificationStatus = data.verification as TwilioVerificationStatus;

      switch (verificationStatus) {
        case TwilioVerificationStatus.APPROVED:
          return "ok";
        case TwilioVerificationStatus.PENDING:
          return "Your phone verification is still pending.";
        case TwilioVerificationStatus.CANCELED:
          return "Verification has been canceled. Please resend the code.";

        case TwilioVerificationStatus.MAX_ATTEMPTS_REACHED:
          return "Maximum verification attempts reached. Please request a new code.";

        case TwilioVerificationStatus.DELETED:
          return "Verification was deleted. Please initiate the verification again.";
        case TwilioVerificationStatus.FAILED:
          return "Verification failed. Please try again.";
        case TwilioVerificationStatus.EXPIRED:
          return "Verification code expired. Please request a new code.";
        default:
          return "An unknown verification status was returned.";
      }
    } else {
      switch (response.status) {
        case 401:
          return "User is invalid or expired. Please send again";
        case 404:
          return "User doesn't exist.";
        case 409:
          return "Number already verified with an account.";
        case 500:
          return "Server error. Please try again later.";
        default:
          return "An unexpected error occurred. Please try again.";
      }
    }
  } catch (error) {
    console.error("Error sending verification code:", error);
    return "Error sending verification code";
  }
}

async function VerifyForgotUsernameCode(code: string, phone: string) {
  const formData = new FormData();
  formData.append("code", code);
  formData.append("phone", phone);

  const options = {
    method: "POST",
    body: formData,
  };

  try {
    const response = await fetch(
      `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/verify-forgot-username-request`,
      options
    );

    if (response.ok) {
      const data = await response.json();
      return {
        message: "ok",
        auth_token: data.identification_token,
      };
    } else {
      switch (response.status) {
        case 400:
          return { message: "Invalid verification code.", auth_token: "none" };
        case 404:
          return {
            message:
              "No verified users associated with the provided phone number.",
            auth_token: "none",
          };
        case 500:
          return {
            message: "Server error. Please try again later.",
            auth_token: "none",
          };
        default:
          return {
            message: "An unexpected error occurred. Please try again.",
            auth_token: "none",
          };
      }
    }
  } catch (error) {
    console.error("Error during forgot username verification:", error);
    return { message: "Error during verification.", auth_token: "none" };
  }
}

async function VerifyForgotPasswordCode(code: string, phone: string) {
  const formData = new FormData();
  formData.append("code", code);
  formData.append("phone", phone);
  const options = {
    method: "POST",
    body: formData,
  };

  try {
    const response = await fetch(
      `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/verify-password-reset-request`,
      options
    );

    if (response.ok) {
      const data = await response.json();
      return {
        message: "ok",
        auth_token: data.identification_token,
      };
    } else {
      switch (response.status) {
        case 400:
          return { message: "Invalid verification code.", auth_token: "none" };
        case 404:
          return {
            message:
              "no verified users associated with the provided phone number",
            auth_token: "none",
          };

        case 500:
          return {
            message: "Server error. Please try again later.",
            auth_token: "none",
          };
        default:
          return {
            message: "An unexpected error occurred. Please try again.",
            auth_token: "none",
          };
      }
    }
  } catch (e) {
    return {
      message: "Error during verification.",
      auth_token: "none",
    };
  }
}

export default VerifyPhone;
