/* Data Models */
import {
  AnalysisModule,
  AnalysisModuleType,
} from "../../../../../../model/DisclosureAnalysis/AnalysisModules/AnalysisModule";
import {
  ComponentAnalysisModule,
  ComponentAnalysisStatus,
} from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/ComponentAnalysisModule";
import {
  ComponentCategories,
  ComponentTypeDisplayMapping,
  ComponentCategoryDisplayMapping,
} from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/Types";
import {
  CustomComponentAnalysisModule,
  CustomComponentAnalysisStatus,
} from "../../../../../../model/DisclosureAnalysis/AnalysisModules/CustomComponentAnalysisModule";
import { MasterReportModule } from "../../../../../../model/DisclosureAnalysis/AnalysisModules/MasterReportModule";

/* Functions and Utils */
import { addCustomAnalysis } from "../../../api/custom";

/* Styling */
import "./DisclosureNavigationAnalyses.css";
import AddCustomAnalysisIcon from "./assets/AddCustomAnalysis.svg";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/* COMPONENT PROPS */
interface DisclosureNavigationAnalysesProps {
  disclosureID: string;
  masterReport: MasterReportModule;
  components: ComponentAnalysisModule[];
  customAnalyses: CustomComponentAnalysisModule[];
  currentAnalysisModule: AnalysisModule | null;
  setCurrentAnalysisModule: React.Dispatch<
    React.SetStateAction<AnalysisModule | null>
  >;
}

/* COMPONENT */
export default function DisclosureNavigationAnalyses({
  disclosureID,
  masterReport,
  components,
  customAnalyses,
  currentAnalysisModule,
  setCurrentAnalysisModule,
}: DisclosureNavigationAnalysesProps): JSX.Element {
  /**
   * UI Helper Functions
   */
  const categorizedComponents = Object.keys(ComponentCategories)?.reduce<{
    [category: string]: ComponentAnalysisModule[];
  }>((acc, categoryType) => {
    acc[categoryType] = components
      .filter((component) =>
        ComponentCategories[categoryType].includes(component.type)
      )
      .sort(
        (a, b) =>
          componentStatusOrder[a.status] - componentStatusOrder[b.status]
      );
    return acc;
  }, {});

  const handleAddCustomAnalysis = () => {
    addCustomAnalysis(disclosureID);
  };

  return (
    <div className="analyses-nav-viewer">
      {/* Master Report */}
      <div
        className={`analyses-nav-viewer-master-report ${
          currentAnalysisModule?.analysis_module_type ===
          AnalysisModuleType.MASTER_REPORT
            ? "selected"
            : ""
        }`}
        onClick={() => setCurrentAnalysisModule(masterReport)}
      >
        Master Report
      </div>
      {/* Base Component Analyzers */}
      {Object.entries(categorizedComponents).map(
        ([categoryName, categoryComponents], index) =>
          categoryComponents.length > 0 && (
            <div key={index} className="analyses-nav-viewer-category">
              <div className="analyses-nav-viewer-category-name">
                {ComponentCategoryDisplayMapping[categoryName]}
              </div>
              <div>
                {categoryComponents.map((component, componentIndex) => (
                  <div
                    key={componentIndex}
                    onClick={() => setCurrentAnalysisModule(component)}
                  >
                    <DisplayBaseComponent
                      component={component}
                      currentAnalysisModule={currentAnalysisModule}
                    />
                  </div>
                ))}
              </div>
            </div>
          )
      )}
      {/* Custom Analyzers */}
      <div className="analyses-nav-viewer-category">
        <div className="analyses-nav-viewer-category-name custom-analysis">
          <div>Custom Analyses</div>{" "}
          <img
            src={AddCustomAnalysisIcon}
            className="analyses-nav-viewer-add-custom-analysis-button"
            onClick={() => handleAddCustomAnalysis()}
            alt="Add custom analysis"
          />
        </div>
        {customAnalyses.length > 0 ? (
          customAnalyses
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((customComponent, index) => (
              <DisplayCustomComponent
                key={customComponent.id || index}
                customComponent={customComponent}
                currentAnalysisModule={currentAnalysisModule}
                setCurrentAnalysisModule={setCurrentAnalysisModule}
              />
            ))
        ) : (
          <div className="analyses-nav-viewer-analysis-type">
            <div
              className="disclosure-nav-new-custom-analysis-tag"
              onClick={() => handleAddCustomAnalysis()}
            >
              New Custom Analysis
            </div>
          </div>
        )}
        <br />
      </div>
    </div>
  );
}

const componentStatusOrder: { [key in ComponentAnalysisStatus]: number } = {
  [ComponentAnalysisStatus.COMPLETE]: 0,
  [ComponentAnalysisStatus.ANALYZING]: 1,
  [ComponentAnalysisStatus.FILECLASSIFYING]: 2,
  [ComponentAnalysisStatus.FILES_NOT_DETECTED]: 3,
  [ComponentAnalysisStatus.UNINITIATED]: 4,
  [ComponentAnalysisStatus.ERROR]: 5,
};

function DisplayBaseComponent({
  component,
  currentAnalysisModule,
}: {
  component: ComponentAnalysisModule;
  currentAnalysisModule: AnalysisModule | null;
}): JSX.Element {
  // If component is currently selected
  if (
    currentAnalysisModule?.analysis_module_type === AnalysisModuleType.COMPONENT
  ) {
    const selectedComponent = currentAnalysisModule as ComponentAnalysisModule;
    if (component.type === selectedComponent.type) {
      switch (component.status) {
        case ComponentAnalysisStatus.FILECLASSIFYING:
          return (
            <div className="analyses-nav-viewer-analysis-type selected fileclassifying">
              {ComponentTypeDisplayMapping[component.type]}
            </div>
          );
        case ComponentAnalysisStatus.ANALYZING:
          return (
            <div className="analyses-nav-viewer-analysis-type selected analyzing">
              {ComponentTypeDisplayMapping[component.type]}
            </div>
          );
        default:
          return (
            <div className="analyses-nav-viewer-analysis-type selected">
              {ComponentTypeDisplayMapping[component.type]}
            </div>
          );
      }
    }
  }

  switch (component.status) {
    case ComponentAnalysisStatus.FILES_NOT_DETECTED:
      return (
        <div className="analyses-nav-viewer-analysis-type files-not-detected">
          {ComponentTypeDisplayMapping[component.type]}
        </div>
      );
    case ComponentAnalysisStatus.FILECLASSIFYING:
      return (
        <div className="analyses-nav-viewer-analysis-type fileclassifying">
          {ComponentTypeDisplayMapping[component.type]}
        </div>
      );
    case ComponentAnalysisStatus.ANALYZING:
      return (
        <div className="analyses-nav-viewer-analysis-type analyzing">
          {ComponentTypeDisplayMapping[component.type]}
        </div>
      );
    case ComponentAnalysisStatus.ERROR:
      return (
        <div className="analyses-nav-viewer-analysis-type error">
          {ComponentTypeDisplayMapping[component.type]}
        </div>
      );
    default:
      return (
        <div className="analyses-nav-viewer-analysis-type">
          {ComponentTypeDisplayMapping[component.type]}
        </div>
      );
  }
}

function DisplayCustomComponent({
  customComponent,
  currentAnalysisModule,
  setCurrentAnalysisModule,
}: {
  customComponent: CustomComponentAnalysisModule;
  currentAnalysisModule: AnalysisModule | null;
  setCurrentAnalysisModule: React.Dispatch<
    React.SetStateAction<AnalysisModule | null>
  >;
}): JSX.Element {
  const getStyleClass = (status: CustomComponentAnalysisStatus): string => {
    switch (status) {
      case CustomComponentAnalysisStatus.ANALYZING:
        return "analyses-nav-viewer-analysis-type analyzing";
      case CustomComponentAnalysisStatus.COMPLETE:
        return "analyses-nav-viewer-analysis-type complete";
      case CustomComponentAnalysisStatus.ERROR:
        return "analyses-nav-viewer-analysis-type error";
      case CustomComponentAnalysisStatus.UNINITIATED:
      default:
        return "analyses-nav-viewer-analysis-type"; // Default styling
    }
  };

  const getSelectedStatus = (): string => {
    if (
      currentAnalysisModule?.analysis_module_type ===
      AnalysisModuleType.CUSTOM_COMPONENT
    ) {
      const currentCustom =
        currentAnalysisModule as CustomComponentAnalysisModule;
      if (currentCustom.id === customComponent.id) {
        return "selected";
      }
    }
    return "";
  };

  return (
    <div
      className={`${getStyleClass(
        customComponent.status
      )} ${getSelectedStatus()}`}
      onClick={() => setCurrentAnalysisModule(customComponent)}
    >
      {customComponent.name}
    </div>
  );
}
