import React, { useState } from "react";
import "./PreliminaryReport.css";
import arrowIcon from "../../../assets/SectionArrow.svg";

import {
  SeverityLevel,
  SeverityLevelOrder,
} from "../../../../../../../../model/DisclosureAnalysis/Elements";
import {
  PrelimTitleReportAnalysis,
  PrelimTitleReportAnalysisItem,
} from "../../../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/PrelimTtitleReport/PrelimTitleReport";
import { SelectedPage } from "../../../../PDFViewer/PDFViewer";

export default function PreliminaryReportAnalysisViewer({
  analysis,
  setSelectedPage,
}: {
  analysis: PrelimTitleReportAnalysis;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}): JSX.Element {
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleSection = (sectionName: string) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [sectionName]: !prevState[sectionName],
    }));
  };

  const renderItems = (items: PrelimTitleReportAnalysisItem[]) => (
    <div className="component-analysis-section-list">
      {items
        .sort(
          (a, b) =>
            SeverityLevelOrder[a.severity] - SeverityLevelOrder[b.severity]
        )
        .map((item, index) => (
          <div
            key={index}
            className={`component-analysis-section-item ${
              item.reference ? "has-reference" : ""
            }`}
            onClick={() => {
              if (item.reference) {
                setSelectedPage({
                  fileID: item.reference.pdf?.file_id,
                  page: item.reference.page,
                  region: item.reference.region,
                } as SelectedPage);
              }
            }}
          >
            <div className="component-analysis-section-item-description bold">
              {item.description}
            </div>
            <div>
              {item.severity !== SeverityLevel.NOTAPPLICABLE && (
                <div className={`severity-box ${item.severity}`}>
                  {item.severity}
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );

  const renderSection = (
    title: string,
    items: PrelimTitleReportAnalysisItem[]
  ) => {
    return (
      <div className="component-analysis-section">
        <div
          className="component-analysis-section-header"
          onClick={() => toggleSection(title)}
        >
          <div>
            <img
              src={arrowIcon}
              alt="toggle"
              className={`component-analysis-section-header-arrow ${
                expandedSections[title] ? "expanded" : "collapsed"
              }`}
            />
            {title}
          </div>
        </div>
        {expandedSections[title] && renderItems(items)}
      </div>
    );
  };

  return (
    <div>
      {renderSection("Liens", analysis.liens)}
      {renderSection("Title Irregularities", analysis.title_irregularities)}
      {renderSection("Easements", analysis.easements)}
      {renderSection("Outstanding Taxes", analysis.outstanding_taxes)}
    </div>
  );
}
