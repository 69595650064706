/* React */
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

/* UI Components */
import Navbar from "../../../Common/Navbar";
import Spinner from "../../../Common/Spinner/LoadingSpinner";

import "../auth.css";
import "./ForgotUsername.css";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

function ForgotUsername() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const [username, setUsername] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const retrieveUsername = async () => {
    setIsLoading(true);
    if (!code) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("identification_token", code);

      const response = await fetch(
        `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/username`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (response.ok) {
        // throw new Error("Failed to retrieve username");
        console.log("worked");
      }

      if (response.status === 400) {
        console.log("expired");
      }

      const data = await response.json();
      setUsername(data.username);
    } catch (error) {
      console.error("Error retrieving username:", error);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Navbar />
      <div className="auth auth--forgot-username">
        <h2 className="auth__title--forgot-username">Username Reveal</h2>

        <div className="auth__form auth__form--forgot-username">
          <div className="forgot-username__instruction">
            Click to reveal username
          </div>

          <div className="auth__controls auth__controls--forgot-username">
            {username ? (
              <div className="forgot-username__revealed-username">
                Your Username:
                <p className="forgot-username__username">{username}</p>
              </div>
            ) : isLoading ? (
              <div className="loading-spinner loading-spinner--small">
                <Spinner />
              </div>
            ) : (
              <button
                onClick={retrieveUsername}
                className="auth__button auth__button--forgot-username"
              >
                Reveal Username
              </button>
            )}
          </div>
        </div>

      </div>
    </div>
  );
}

export default ForgotUsername;
