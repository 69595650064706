/* React */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

/* UI Components */
import ErrorCircle from "../../../../assets/error_circle.svg";
import Spinner from "../../../Common/Spinner/LoadingSpinner";
import Navbar from "../../../Common/Navbar";

/* Functionality */
import { UserLogin } from "../APIs/account";
import { LoginStatusCodes } from "../APIs/accountStatusCodes";
import { getLoginErrorMessage } from "../APIs/statusCodeMapper";

/* Requirements */
import {
  notSignedInRequirement,
  withRequirements,
} from "../../../Requirements";

/* Styling */
import "./login.css";
import "../auth.css";

function Login() {
  /* 
  ================================
  VARS
  ================================
  */
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  /* 
  ================================
  UI Functionality
  ================================
  */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrorMessage("");
  };

  /* 
  ================================
  SUBMISSION
  ================================
  */
  const disableSubmission =
    formData.username === "" || formData.password === "";

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    // Prepare the form data for login
    const uploadData = new FormData();
    uploadData.append("username", formData.username);
    uploadData.append("password", formData.password);

    // Attempt to sign up the user and recieve login status
    const loginStatus: LoginStatusCodes = await UserLogin(uploadData);

    // If login is successful, navigate to dashboard
    if (loginStatus === LoginStatusCodes.SUCCESS) {
      setIsLoading(false);
      navigate("/dashboard");
    } else {
      // Retrieve an error message based on login status
      const message = getLoginErrorMessage(loginStatus);
      setErrorMessage(message);
    }

    setIsLoading(false);
  };

  /* 
  ================================
  RENDERING
  ================================
  */
  return (
    <div>
      <Navbar />
      <div className="auth auth--login">
        <h2 className="auth__title auth__title--login">Log In</h2>

        <form className="auth__form auth__form--login">
          <div className="auth__inputs auth__inputs--login">
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              className="auth__input auth__input--login"
              placeholder="Username"
            />

            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              className="auth__input auth__input--login"
              placeholder="Password"
            />
          </div>

          <div className="auth__error-message auth__error-message--login">
            {errorMessage && (
              <p className="auth__alert auth__alert--login">
                <img
                  src={ErrorCircle}
                  alt="Error Icon"
                  className="auth__error-icon auth__error-icon--login"
                />
                {errorMessage}
              </p>
            )}
          </div>

          <div className="auth__controls auth__controls--login">
            {isLoading ? (
              <div className="loading-spinner loading-spinner--small">
                <Spinner />
              </div>
            ) : (
              <button
                onClick={handleSubmit}
                className={`auth__button auth__botton--login ${
                  disableSubmission ? "auth__button--disabled" : ""
                }`}
                disabled={disableSubmission}
              >
                Continue
              </button>
            )}
          </div>

          <div className="auth__links auth__links--login">
            <a
              href="/send-phone-verification-code?action=forgot-password"
              className="auth__action-link auth__action-link--login"
            >
              Reset Password
            </a>{" "}
            |{" "}
            <a
              href="/send-phone-verification-code?action=forgot-username"
              className="auth__action-link auth__action-link--login"
            >
              Forgot Username
            </a>
          </div>
        </form>

        <div className="auth__links auth__links--login">
          Don't have an account?{" "}
          <a
            className="auth__action-link auth__action-link--login"
            href="/signup"
          >
            Sign Up
          </a>
        </div>
      </div>
    </div>
  );
}

export default withRequirements(Login, [notSignedInRequirement]);
