/* React */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* Functions and Utils */
import { Auth, getWithAuth } from "../firebase/authentication/auth";
import { User } from "firebase/auth";

/* UI */
import "./wrapper.css";
import LoadingSpinner from "./Common/Spinner/LoadingSpinner";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

type Requirement = {
  check: () => Promise<boolean>;
  redirect: string;
};

function withRequirements(
  Component: React.ComponentType,
  requirements: Requirement[]
) {
  function WithRequirements() {
    const [isLoading, setIsLoading] = useState(true);
    const [canRender, setCanRender] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      const runChecks = async () => {
        for (const { check, redirect } of requirements) {
          const passed = await check();
          if (!passed) {
            navigate(redirect);
            return;
          }
        }
        setCanRender(true);
        setIsLoading(false);
      };

      runChecks();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
      return (
        <div className="wrapper-loader">
          <div className="loading-spinner loading-spinner--large">
            <LoadingSpinner />
          </div>
          <p className="wrapper-loader__loading">Loading</p>
        </div>
      );
    }

    return canRender ? <Component /> : null;
  }

  return WithRequirements;
}

const userSignedInRequirement: Requirement = {
  check: async () => {
    return new Promise((resolve) => {
      Auth.onAuthStateChanged((authUser: User | null) => {
        if (!authUser) {
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  },
  redirect: "/login",
};

const notSignedInRequirement: Requirement = {
  check: async () => {
    return new Promise((resolve) => {
      Auth.onAuthStateChanged((authUser: User | null) => {
        if (!authUser) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  },
  redirect: "/dashboard",
};

const userPhoneVerifiedRequirement: Requirement = {
  check: async () => {
    try {
      const response = await getWithAuth(
        `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/phone`
      );
      const data = await response.json();

      return data.verified;
    } catch (error) {
      console.error("Error checking phone verification:", error);
      return false;
    }
  },
  redirect: "/send-phone-verification-code?action=account-verify",
};

const userNotAlreadyPhoneVerified: Requirement = {
  check: async () => {
    try {
      const response = await getWithAuth(
        `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/phone`
      );
      const data = await response.json();

      return !data.verified;
    } catch (error) {
      console.error("Error checking phone verification:", error);
      return false;
    }
  },
  redirect: "/dashboard",
};

const userSubscribedOrTrialedRequirement: Requirement = {
  check: async () => {
    // Check trial validity
    try {
      const response = await getWithAuth(
        `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/trial/validity`
      );
      const data = await response.json();

      if (data.valid_trial) {
        return true;
      }
    } catch (error) {
      console.error("Error checking trial validity:", error);
      return false;
    }

    // Check for subscription validity
    try {
      const response = await getWithAuth(
        `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/subscription/validity`
      );
      const data = await response.json();

      if (data.valid_subscription) {
        return true;
      }
    } catch (error) {
      console.error("Error checking subscription validity:", error);
      return false;
    }

    return false;
  },
  redirect: "/purchase",
};

const userIsAdminRequirement: Requirement = {
  check: async () => {
    try {
      const response = await getWithAuth(
        `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/is-admin`
      );
      const data = await response.json();

      return data.admin;
    } catch (error) {
      console.error("Error checking admin status:", error);
      return false;
    }
  },
  redirect: "/dashboard",
};

export {
  withRequirements,
  userSignedInRequirement,
  userPhoneVerifiedRequirement,
  userSubscribedOrTrialedRequirement,
  userNotAlreadyPhoneVerified,
  notSignedInRequirement,
  userIsAdminRequirement,
};
