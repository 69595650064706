/* UI Components */
import Navbar from "../../Common/Navbar";

/* Data Models */
import { SubscriptionLevel } from "../../../model/DisclosureAnalysis/SubscriptionLevel";

/* Functions and Utils */
import { postWithAuth } from "../../../firebase/authentication/auth";
import { withRequirements, userSignedInRequirement } from "../../Requirements";

/* Styling */
import whiteCheckIcon from "../../../assets/white-check-icon.svg";
import "./Paywall.css";

const API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

function Paywall() {
  const checkoutSession = async (level: SubscriptionLevel) => {
    const session_url = `${HTTP_PROTOCAL}://${API_BASE_URL}/api/account/subscription/create-checkout-session`;
    const formData = new FormData();
    formData.append("subscription_level", level);

    const response = await postWithAuth(session_url, formData);
    const data = await response.json();
    window.location.replace(data.url);
  };

  return (
    <div>
      <Navbar />
      <div className="paywall">
        <div
          className="landing-plans"
          id="landing-plans-id"
          style={{ margin: "none" }}
        >
          <div className="landing-section-banner" style={{ marginTop: "20px" }}>
            Plans
          </div>
          <div className="landing-section-banner-subtext">
            Simple, transparent pricing that grows with you.
          </div>
          <div className="landing-plans-options">
            <div className="landing-plans-option-gradient-edge-left" />
            {/* BASIC */}
            <div className="landing-plans-option">
              <div className="landing-plans-option-row-container">
                <div className="landing-plans-option-header">BASIC</div>
                <div className="landing-plans-option-header-subtext">
                  Homebuyer's and beginner agents
                </div>
              </div>
              <div className="landing-plans-option-internal-separator" />
              <div className="landing-plans-option-row-container">
                <div className="landing-plans-option-header-price">
                  $49
                  <div className="landing-plans-option-header-billing">
                    Billed Monthly
                  </div>
                </div>
                <div
                  className="landing-plans-start-button purchase"
                  onClick={() => {
                    checkoutSession(SubscriptionLevel.BASIC);
                  }}
                >
                  Select
                </div>
              </div>
              <div className="landing-plans-option-internal-separator" />
              <div className="landing-plans-option-benefits-list">
                <div className="landing-plans-option-benefits-list-item">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>5 disclosure packages analyses per month</span>
                </div>
                <div className="landing-plans-option-benefits-list-item">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Analysis on all relevant documents</span>
                </div>
                <div className="landing-plans-option-benefits-list-item">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Share analysis with client feature</span>
                </div>
              </div>
            </div>
            {/* PRO */}
            <div className="landing-plans-option pro">
              <div className="landing-plans-option-row-container">
                <div className="landing-plans-option-header pro">
                  PRO
                  <div className="landing-plans-option-header-popular-tag">
                    Popular
                  </div>
                </div>
                <div className="landing-plans-option-header-subtext pro">
                  The active agent's preference.
                </div>
              </div>
              <div className="landing-plans-option-internal-separator pro" />
              <div className="landing-plans-option-row-container">
                <div className="landing-plans-option-header-price pro">
                  $99
                  <div className="landing-plans-option-header-billing">
                    Billed Monthly
                  </div>
                </div>
                <div
                  className="landing-plans-start-button purchase pro"
                  onClick={() => {
                    checkoutSession(SubscriptionLevel.PRO);
                  }}
                >
                  Select
                </div>
              </div>
              <div className="landing-plans-option-internal-separator pro" />
              <div className="landing-plans-option-benefits-list pro">
                <div className="landing-plans-option-benefits-list-item pro">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>20 disclosure packages analyses per month</span>
                </div>
                <div className="landing-plans-option-benefits-list-item pro">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Analysis on all relevant documents</span>
                </div>
                <div className="landing-plans-option-benefits-list-item pro">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Share analysis with client feature</span>
                </div>
                <div className="landing-plans-option-benefits-list-item pro">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Agnsy Document Chat</span>
                </div>
                <div className="landing-plans-option-benefits-list-item pro">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Custom Document Analyzer</span>
                </div>
                <div className="landing-plans-option-benefits-list-item pro">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Early access to new features</span>
                </div>
              </div>
            </div>
            {/* VOLUME */}
            <div className="landing-plans-option">
              <div className="landing-plans-option-row-container">
                <div className="landing-plans-option-header">VOLUME</div>
                <div className="landing-plans-option-header-subtext">
                  Top performers and teams.
                </div>
              </div>
              <div className="landing-plans-option-internal-separator" />
              <div className="landing-plans-option-row-container">
                <div className="landing-plans-option-header-price">
                  $199
                  <div className="landing-plans-option-header-billing">
                    Billed Monthly
                  </div>
                </div>
                <div
                  className="landing-plans-start-button purchase"
                  onClick={() => {
                    checkoutSession(SubscriptionLevel.VOLUME);
                  }}
                >
                  Select
                </div>
              </div>
              <div className="landing-plans-option-internal-separator" />

              <div className="landing-plans-option-benefits-list">
                <div className="landing-plans-option-benefits-list-item">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>50 disclosure packages analyses per month</span>
                </div>
                <div className="landing-plans-option-benefits-list-item">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Analysis on all relevant documents</span>
                </div>
                <div className="landing-plans-option-benefits-list-item">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Share analysis with client feature</span>
                </div>
                <div className="landing-plans-option-benefits-list-item">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Agnsy Document Chat</span>
                </div>
                <div className="landing-plans-option-benefits-list-item">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Custom Document Analyzer</span>
                </div>
                <div className="landing-plans-option-benefits-list-item">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Early access to new features</span>
                </div>
              </div>
            </div>
            <div className="landing-plans-option-gradient-edge-right" />
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
}

export default withRequirements(Paywall, [userSignedInRequirement]);
