import React, { useState } from "react";

/* Utility Functions */
import {
  postWithAuth,
  getCurrentUser,
} from "../../../../../firebase/authentication/auth";

/* Styling */
import "./ShareDisclosureAnalysisButton.css";

const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

export default function ShareDisclosureAnalysisButton(props: {
  uid: string | null;
  disclosure_id: string | null;
  share_link: string | null;
}): JSX.Element {
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showCopiedBubble = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  const shareDisclosureAnalysis = async () => {
    setIsLoading(true);

    const { uid, disclosure_id, share_link } = props;

    if (share_link) {
      navigator.clipboard.writeText(share_link);
      showCopiedBubble();
      setIsLoading(false);
      return;
    }

    let userId: string | null = uid;
    if (!uid || !disclosure_id) {
      const currentUser = await getCurrentUser();
      userId = currentUser?.uid || null;
    }

    try {
      const result = await postWithAuth(
        `${HTTP_PROTOCAL}://` +
          process.env.REACT_APP_DISCLOSURE_ANALYSIS_API +
          `/api/disclosure/share/${userId}/${disclosure_id}`
      );

      const resultURL = await result.json();

      if (resultURL.url) {
        navigator.clipboard.writeText(resultURL.url);
        showCopiedBubble();
      } else {
        alert("Failed to copy link to clipboard");
      }
    } catch (error) {
      alert("An error occurred while sharing the disclosure.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="share-disclosure-analysis-button"
      onClick={shareDisclosureAnalysis}
    >
      {isLoading ? (
        <div className="share-disclosure-analysis-button-spinner" />
      ) : (
        "Share"
      )}
      <div
        className={`${
          isCopied
            ? "share-disclosure-analysis-button-coming-copied"
            : "share-disclosure-analysis-button-coming-not"
        }`}
      >
        Link copied to clipboard
      </div>
    </div>
  );
}
