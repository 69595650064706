import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Scroll: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (location.hash === "#plans") {
        const plansElement = document.getElementById("landing-plans-id");
        if (plansElement) {
          plansElement.scrollIntoView({ behavior: "smooth" });
        }
      } else if (location.hash === "#contact") {
        const contactElement = document.getElementById("faq-contact-form-id");
        if (contactElement) {
          contactElement.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    };

    setTimeout(() => {
      handleScroll();
    }, 0);
  }, [location]);
  return null;
};

export default Scroll;
