/* React */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

/* UI Components */
import DashboardButton from "./DashboardButton";
import LogoutButton from "./LogoutButton";

/* Functionality */
import { logoutCurrentUser } from "../../../firebase/authentication/functions";

/* Styling */
import "./Navbar.css";
import LogoWhite from "../../../assets/agnsy-icon.svg";
import MobileMenuIconWhite from "../../../assets/menu-icon.svg";
import MobileMenuIconBlack from "../../../assets/menu-icon-black.svg";

function NavbarAuthorized() {
  const navigate = useNavigate();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = () => {
    logoutCurrentUser().then(() => {
      navigate("/");
    });
  };

  return (
    <div className="navbar">
      <div
        className={`navbar-left-content ${isMobileMenuOpen ? "active" : ""}`}
      >
        <div className="navbar-mobile-toggle" onClick={toggleMobileMenu}>
          <img
            src={isMobileMenuOpen ? MobileMenuIconBlack : MobileMenuIconWhite}
            alt="Mobile menu toggle"
            className="navbar-mobile-toggle-icon"
          />
        </div>
        <Link to="/">
          <img src={LogoWhite} className="navbar-logo-white" alt="Logo" />
        </Link>
        <div className="navbar-vertical-separator" />
        <div className="navbar-routes">
          <Link to="/disclosure/upload" className="navbar-route">
            Disclosure Analysis
          </Link>
          <Link to="/#plans" className="navbar-route">
            Plans
          </Link>
          <Link
            to="/"
            style={{ display: `${!isMobileMenuOpen ? "none" : ""}` }}
            className="navbar-route"
            onClick={handleLogout}
          >
            Logout
          </Link>
          <Link to="/faq" className="navbar-route">
            FAQ
          </Link>
        </div>
      </div>
      <div className="navbar-right-content">
        <LogoutButton />
        <Link to="/dashboard" style={{ textDecoration: "none" }}>
          <DashboardButton />
        </Link>
      </div>
    </div>
  );
}

export default NavbarAuthorized;
