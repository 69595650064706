/* React */
import React, { useEffect, useRef, useState } from "react";

/* UI Components */
import { Document, Page, pdfjs } from "react-pdf";

/* Data Models */
import { BoundingBox } from "../../../../../model/DisclosureAnalysis/Reference/BoundingBox";
import { PDFFile } from "../../../../../model/DisclosureAnalysis/PDFFile";

/* Utility Functions */
import { blobToUint8Array } from "./utils";

/* Assets */
// import arrowIcon from "../AnalysisModuleViewer/assets/SectionArrow.svg";
// import arrowIconGreyed from "../AnalysisModuleViewer/assets/SectionArrowGreyed.svg";

/* Styling */
import "./PDFViewer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export interface SelectedPage {
  fileID: string;
  page: number;
  region?: BoundingBox;
}

export interface PDFViewerProps {
  pdfs: PDFFile[] | null;
  selected: SelectedPage | null;
}

export interface PageDimensions {
  width: number;
  height: number;
}

const ArrowIcon = () => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.2394 15.9702H19.9567L20.1618 15.7124C21.5408 13.9788 21.5408 11.9081 20.1618 10.1745L13.1082 1.30722C12.438 0.464177 11.5462 -2.38419e-05 10.598 -2.38419e-05C9.64989 -2.38419e-05 8.75809 0.464177 8.08784 1.30722L1.0343 10.1745C-0.344704 11.9081 -0.344704 13.9788 1.0343 15.7124L1.2394 15.9702Z"
      className="page-arrow-icon"
    />
  </svg>
);

const ArrowIconGreyed = () => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.2394 15.9702H19.9567L20.1618 15.7124C21.5408 13.9788 21.5408 11.9081 20.1618 10.1745L13.1082 1.30722C12.438 0.464177 11.5462 -2.38419e-05 10.598 -2.38419e-05C9.64989 -2.38419e-05 8.75809 0.464177 8.08784 1.30722L1.0343 10.1745C-0.344704 11.9081 -0.344704 13.9788 1.0343 15.7124L1.2394 15.9702Z"
      className="page-arrow-icon-grey"
    />
  </svg>
);

function PDFViewer({ pdfs, selected }: PDFViewerProps): JSX.Element {
  console.log(selected);
  /**
   * Constants
   */
  const documentContainerRef = useRef<HTMLDivElement>(null);
  const pageContainerRef = useRef<HTMLDivElement>(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumberPages(numPages);
    if (documentContainerRef.current) {
      setDocumentDimensions({
        width: documentContainerRef.current.clientWidth,
        height: documentContainerRef.current.clientHeight,
      });
    }
  }
  function onPageLoadSuccess(): void {
    if (pageContainerRef.current) {
      setPageDimensions({
        width: pageContainerRef.current.clientWidth,
        height: pageContainerRef.current.clientHeight,
      });
    }
  }

  /**
   * State Variables
   */
  const [documentDimensions, setDocumentDimensions] = useState<PageDimensions>({
    width: 0,
    height: 0,
  });
  const [pageDimensions, setPageDimensions] = useState<PageDimensions>({
    width: 0,
    height: 0,
  });
  const [numberPages, setNumberPages] = useState<number>(
    Number.MAX_SAFE_INTEGER
  );
  const [uint8ArrayContent, setUint8ArrayContent] = useState<Uint8Array | null>(
    null
  );
  const [currentPDFIndex, setCurrentPDFIndex] = useState<number>(0);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [carouselOffset, setCarouselOffset] = useState(50);
  const [boundingBoxStyle, setBoundingBoxStyle] =
    useState<React.CSSProperties | null>(null);

  /**
   * Effects
   */
  useEffect(() => {
    if (selected && selected.fileID && selected.page && pdfs) {
      const selectedIndex = pdfs.findIndex(
        (pdf) => pdf.file_id === selected.fileID
      );
      if (selectedIndex !== -1) {
        setCurrentPDFIndex(selectedIndex);
      }
      setCurrentPageNumber(selected.page);
    }
    return () => {
      setCurrentPDFIndex(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  // Fetch PDFs
  useEffect(() => {
    const fetchData = async () => {
      if (
        pdfs &&
        pdfs.length > 0 &&
        currentPDFIndex >= 0 &&
        currentPDFIndex < pdfs.length
      ) {
        const currentPDF = pdfs[currentPDFIndex];
        try {
          const uint8Array = await blobToUint8Array(currentPDF.data);
          setUint8ArrayContent(uint8Array);
        } catch (error) {
          console.error("Error converting Blob to Uint8Array:", error);
        }
      }
    };

    fetchData();
  }, [pdfs, currentPDFIndex, selected]);

  // Set bounding box
  useEffect(() => {
    if (selected?.region && pageDimensions.width && pageDimensions.height) {
      const { top_left, bottom_right } = selected.region;
      const style = {
        position: "absolute",
        border: "2px solid red",
        backgroundColor: "rgba(255, 0, 0, 0.139)",
        borderRadius: "10px",
        top: `calc(${top_left.y * pageDimensions.height}px)`,
        left: `calc(${top_left.x * pageDimensions.width}px)`,
        width: `calc(${
          (bottom_right.x - top_left.x) * pageDimensions.width
        }px)`,
        height: `calc(${
          (bottom_right.y - top_left.y) * pageDimensions.height
        }px)`,
        pointerEvents: "none",
      } as React.CSSProperties;
      setBoundingBoxStyle(style);
    } else {
      setBoundingBoxStyle(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    setBoundingBoxStyle(null);
    setCurrentPDFIndex(0);
    setCurrentPageNumber(1);
  }, [pdfs]);

  useEffect(() => {
    const handleZoom = () => {
      if (documentContainerRef.current) {
        setDocumentDimensions({
          width: documentContainerRef.current.clientWidth,
          height: documentContainerRef.current.clientHeight,
        });
      }
    };

    window.addEventListener("resize", handleZoom);

    return () => {
      window.removeEventListener("resize", handleZoom);
    };
  }, []);

  /**
   * UI Functionality
   */
  const slideCarouselLeft = () => {
    setCarouselOffset(carouselOffset + 25);
    setBoundingBoxStyle(null);
  };

  const slideCarouselRight = () => {
    setCarouselOffset(carouselOffset - 25);
    setBoundingBoxStyle(null);
  };

  const goToPreviousPDF = () => {
    if (currentPDFIndex > 0) {
      setCurrentPDFIndex(currentPDFIndex - 1);
      slideCarouselLeft();
      setBoundingBoxStyle(null);
      setCurrentPageNumber(1);
    }
  };

  const goToNextPDF = () => {
    if (pdfs && currentPDFIndex < pdfs.length - 1) {
      setCurrentPDFIndex(currentPDFIndex + 1);
      slideCarouselRight();
      setBoundingBoxStyle(null);
      setCurrentPageNumber(1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPageNumber > 1) {
      setCurrentPageNumber(currentPageNumber - 1);
      setBoundingBoxStyle(null);
    }
  };

  const goToNextPage = () => {
    if (currentPageNumber < numberPages) {
      setCurrentPageNumber(currentPageNumber + 1);
      setBoundingBoxStyle(null);
    }
  };

  const file = React.useMemo(() => {
    if (uint8ArrayContent) {
      return { data: uint8ArrayContent };
    }
    return null;
  }, [uint8ArrayContent]);

  /**
   * RENDERING
   */
  if (!uint8ArrayContent) {
    return (
      <div className="disclosure-analysis-shimmy-pdf-viewer">
        <div className="disclosure-analysis-shimmy-pdf-viewer-iframe" />
      </div>
    );
  }
  if (!pdfs || pdfs.length === 0) {
    return <div className="pdf-viewer no-files">No PDFs to show</div>;
  } else {
    const currentPDF = pdfs[currentPDFIndex];
    const previousPDF = pdfs[currentPDFIndex - 1];
    const nextPDF = pdfs[currentPDFIndex + 1];

    return (
      <div className="pdf-viewer" ref={documentContainerRef}>
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={
            <div
              style={{
                minHeight: pageDimensions.height,
                minWidth: pageDimensions.width,
                borderRadius: "1.3vh",
                marginTop: "26px",
              }}
              className="disclosure-analysis-shimmy-pdf-viewer"
            >
              <div className="disclosure-analysis-shimmy-pdf-viewer-iframe" />
            </div>
          }
        >
          <div
            className="pdf-viewer-filenames-container"
            style={{
              width: `${pageDimensions.width + 80}px`,
              marginLeft: "-40px",
            }}
          >
            <div
              className={`pdf-viewer-filename-previous ${
                currentPDFIndex === 0 && "disabled"
              }`}
              onClick={goToPreviousPDF}
            >
              {previousPDF?.fileName}
            </div>
            <div className="pdf-viewer-filename-current">
              {currentPDF?.fileName}
            </div>
            <div
              className={`pdf-viewer-filename-next ${
                currentPDFIndex === pdfs.length - 1 && "disabled"
              }`}
              onClick={goToNextPDF}
            >
              {nextPDF?.fileName}
            </div>
          </div>
          <div className="pdf-viewer-content">
            <div className="pdf-viewer-container" ref={pageContainerRef}>
              <Page
                pageNumber={currentPageNumber}
                height={documentDimensions.height}
                className="pdf-viewer-container-page"
                onLoadSuccess={onPageLoadSuccess}
                loading={
                  <div
                    style={{
                      minHeight: pageDimensions.height,
                      minWidth: pageDimensions.width,
                      borderRadius: "1.3vh",
                      marginTop: "26px",
                    }}
                  ></div>
                }
              />
              {boundingBoxStyle && (
                <div style={boundingBoxStyle}>
                  <button
                    style={{
                      position: "absolute",
                      top: "-10px",
                      right: "-10px",
                      backgroundColor: "red",
                      color: "white",
                      border: "none",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      pointerEvents: "auto",
                      zIndex: 10,
                    }}
                    onClick={() => setBoundingBoxStyle(null)}
                  >
                    X
                  </button>
                </div>
              )}
            </div>
            <div className="pdf-viewer-page-controls">
              <div className="pdf-viewer-page-controls-page-number-wrapper">
                <div className="pdf-viewer-page-controls-page-number">
                  {currentPageNumber}
                </div>
              </div>
              {/* Previous Page */}
              <div
                onClick={goToPreviousPage}
                className={`pdf-viewer-page-controls-page-up-wrapper ${
                  currentPageNumber === 1 ? "disabled" : ""
                }`}
              >
                <div className="pdf-viewer-page-controls-page-up">
                  {currentPageNumber === 1 ? (
                    <ArrowIconGreyed />
                  ) : (
                    <ArrowIcon />
                  )}
                </div>
              </div>
              {/* Next Page */}
              <div
                onClick={goToNextPage}
                className={`pdf-viewer-page-controls-page-down-wrapper ${
                  currentPageNumber >= numberPages ? "disabled" : ""
                }`}
              >
                <div
                  className={`pdf-viewer-page-controls-page-down ${
                    currentPageNumber >= numberPages ? "disabled" : ""
                  }`}
                >
                  {currentPageNumber >= numberPages ? (
                    <ArrowIconGreyed />
                  ) : (
                    <ArrowIcon />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Document>
      </div>
    );
  }
}

function areEqual(prevProps: PDFViewerProps, nextProps: PDFViewerProps) {
  return (
    prevProps.pdfs === nextProps.pdfs &&
    prevProps.selected === nextProps.selected
  );
}

export default React.memo(PDFViewer, areEqual);
