import { LoginStatusCodes, SignupStatusCodes } from "./accountStatusCodes";
import { LoginMessage, SignupMessage } from "./statusMessages";

export const getSignupErrorMessage = (status: SignupStatusCodes) => {
    switch (status){
        case SignupStatusCodes.CONFLICT_USERNAME:
            return SignupMessage.CONFLICT_USERNAME;
        case SignupStatusCodes.CONFLICT_PHONE_NUMBER:
            return SignupMessage.CONFLICT_PHONE_NUMBER;
        case SignupStatusCodes.SERVER_ERROR:
            return SignupMessage.SERVER_ERROR;
        case SignupStatusCodes.UNEXPECTED_ERROR:
        default:
            return SignupMessage.UNEXPECTED_ERROR
    }
}

export const getLoginErrorMessage = (status: LoginStatusCodes) => {
    switch (status) {
        case LoginStatusCodes.INVALID_CREDENTIALS:
            return LoginMessage.INVALID_CREDENTIALS;
        case LoginStatusCodes.SERVER_ERROR:
            return LoginMessage.SERVER_ERROR;
        case LoginStatusCodes.UNEXPECTED_ERROR:
        default:
            return LoginMessage.UNEXPECTED_ERROR
    }
}