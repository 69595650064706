import { getCurrentUser } from "../../../../firebase/authentication/auth";

const WEBSOCKET_SSL_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "wss" : "ws";
const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;

export async function getDisclosureWebSocketForCurrentUser(
  disclosureId: string | null
) {
  const user = await getCurrentUser();
  const uid = user?.uid;

  const authToken = await user?.getIdToken();

  if (!uid) {
    throw new Error("User is not authenticated");
  }

  if (!disclosureId) {
    throw new Error("Disclosure Id is not provided");
  }

  return await getDisclosureWebSocket(uid, disclosureId, authToken);
}

export async function getDisclosureWebSocket(
  uid: string,
  disclosureId: string,
  authToken?: string | null
) {
  if (!authToken) {
    const user = await getCurrentUser();
    authToken = await user?.getIdToken();
  }

  const wsUrl = `${WEBSOCKET_SSL_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/analysis/subscribe/${uid}/${disclosureId}?auth_token=${authToken}`;
  return new WebSocket(wsUrl);
}

export async function getDisclosureWebSocketFromLink(link: string) {
  return new WebSocket(
    `${WEBSOCKET_SSL_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/analysis/subscribe/${link}`
  );
}
