export enum AuthStatusCode {
  OK = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  CONFLICT = 409,
  SERVER_ERROR = 500,
}

/**
 * Enum for the various status codes returned during the signup process.
 *
 * @enum {number}
 * @property {number} CREATED - User successfully created (HTTP 201).
 * @property {number} CONFLICT_USERNAME - Username already in use (HTTP 409).
 * @property {number} CONFLICT_PHONE_NUMBER - Phone verified with another user (HTTP 409).
 * @property {number} SERVER_ERROR - Server error occurred (HTTP 500).
 * @property {number} UNEXPECTED_ERROR - An unexpected error occurred (Custom 600).
 *
 * Note: The backend returns 409 for both user and phone number conflicts.
 * Custom codes 409001 and 409002 are used to handle these specific cases gracefully.
 */
export enum SignupStatusCodes {
  CREATED = 201,
  CONFLICT_PHONE_NUMBER = 409001,
  CONFLICT_USERNAME = 409002,
  SERVER_ERROR = 500,
  UNEXPECTED_ERROR = 600,
}

/**
 * Enum for the various status codes returned during the login process.
 *
 * @enum {number}
 * @property {number} SUCCESS - Login token retrieved successfully (HTTP 200).
 * @property {number} INVALID_CREDENTIALS - Invalid username/password combination (HTTP 400).
 * @property {number} SERVER_ERROR - Server error occurred (HTTP 500).
 * @property {number} UNEXPECTED_ERROR - An unexpected error occurred (Custom 600).
 *
 * Note: Custom error codes are used for clarity and to differentiate between
 * standard HTTP errors and application-specific errors.
 */
export enum LoginStatusCodes {
  SUCCESS = 200,
  INVALID_CREDENTIALS = 400,
  SERVER_ERROR = 500,
  UNEXPECTED_ERROR = 600,
}
