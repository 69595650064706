/* React */
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

/* UI Components */
import Navbar from "../../../Common/Navbar";
import ErrorCircle from "../../../../assets/error_circle.svg";
import Spinner from "../../../Common/Spinner/LoadingSpinner";

import "./ForgotPassword.css";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

function ForgotPassword() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [message, setMessage] = useState<string>("");
  const [passwordReset, setPasswordReset] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setMessage("");
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateForm(name, value);
  };

  const navigate = useNavigate();

  const validateForm = (name: string, value: string) => {
    let error = "";

    if (!disableSubmission) {
      error = "";
    } else if (name === "password" && value.length < 6 && value.length > 0) {
      error = "Password must be at least 6 characters.";
    } else if (
      (name === "password" && value !== formData.confirmPassword) ||
      (name === "confirmPassword" && value !== formData.newPassword)
    ) {
      error = "Passwords do not match.";
    }

    setMessage(error);
  };

  const resetPassword = async () => {
    setIsLoading(true);
    setMessage("");

    try {
      if (!code) {
        setMessage("Please try again.");
        return;
      }
      const formBody = new FormData();
      formBody.append("new_password", formData.newPassword);
      formBody.append("identification_token", code);

      const response = await fetch(
        `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/submit-password-reset`,
        {
          method: "POST",
          body: formBody,
        }
      );

      if (response.ok) {
        setMessage("Password has been reset successfully!");
        setPasswordReset(true);
      } else if (response.status === 400) {
        setMessage("Expired or invalid token.");
      } else {
        setMessage("An unexpected error occurred. Please try again.");
      }
    } catch (error) {
      setMessage("Error resetting password. Please try again.");
    }

    setIsLoading(false);
  };

  const handleButtonClick = () => {
    if (passwordReset){
      navigate("/login");
    } else {
      resetPassword();
    }
  }

  const disableSubmission =
    formData.newPassword === "" ||
    formData.confirmPassword === "" ||
    formData.newPassword !== formData.confirmPassword ||
    formData.newPassword.length < 6;

  return (
    <div>
      <Navbar />
      <div className="auth auth--forgot-password">
        <div className="auth__title auth__title--forgot-password">Reset Password</div>

        <div className="auth__form auth__form--forgot-password">
          <div className="forgot-password__instruction">
            Enter your new password
          </div>

          <div className="auth__inputs auth__inputs--forgot-password">
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              value={formData.newPassword}
              onChange={handleChange}
              required
              className="auth__input auth__input--forgot--password"
              placeholder="New Password"
            />

            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
              className="auth__input auth__input--forgot--password"
              placeholder="Confirm Password"
            />
          </div>

          <div className="auth__error-message auth__error-message--forgot-password">
            {message && (
              <p
                className={`auth__alert ${
                  message === "Password has been reset successfully!"
                    ? "auth__alert--password-reset-success"
                    : "auth__alert--password-reset-error"
                }`}
              >
                {message !== "Password has been reset successfully!" && (
                  <img
                    src={ErrorCircle}
                    alt="Error Icon"
                    className="auth__error-icon auth__error-icon--phone-verification"
                  />
                )}
                {message}
              </p>
            )}
          </div>

          <div className="auth__controls auth__controls--forgot-password">
            {isLoading ? (
              <div className="loading-spinner loading-spinner--small">
                <Spinner />
              </div>
            ) : (
              <button
                onClick={handleButtonClick}
                className={`auth__button auth__button--forgot--password ${
                  disableSubmission ? "auth__button--disabled" : ""
                }`}
                disabled={disableSubmission}
              >
                {passwordReset ? "Login Now" : "Reset Password"}              
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
