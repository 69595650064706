/* React */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

/* Data Models */
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";

/* API Functions */
import { UserSignup, UserLogin } from "../APIs/account";
import { LoginStatusCodes, SignupStatusCodes } from "../APIs/accountStatusCodes";
import { SignupMessage } from "../APIs/statusMessages";

/* Utility Functions */
import { getSignupErrorMessage } from "../APIs/statusCodeMapper";

/* Requirements */
import {
  notSignedInRequirement,
  withRequirements,
} from "../../../Requirements";

/* UI Components */
import Navbar from "../../../Common/Navbar";
import Spinner from "../../../Common/Spinner/LoadingSpinner";

/* Styling */
import ErrorCircle from "../../../../assets/error_circle.svg";
import "react-phone-number-input/style.css";
import "../auth.css";
import "./signup.css";

function Signup() {
  /* 
  ================================
  VARS
  ================================
  */
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
    fullName: "",
    phoneNumber: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const navigate = useNavigate();

  /* 
  ================================
  UI Functionality
  ================================
  */
  const validatePhoneNumber = (phoneNumber: string) => {
    const phone = parsePhoneNumberFromString(phoneNumber);
    if (!phone || !phone.isValid()) {
      return "Invalid phone number format";
    }
    return "";
  };
  const validateForm = (name: string, value: string) => {
    let error = "";

    if (!disableSubmission) {
      setErrorMessage(error);
      return;
    }

    if (name === "email" && !/\S+@\S+\.\S+/.test(value) && value.length > 0) {
      error = "Invalid email format.";
    } else if (name === "password" && value.length < 6 && value.length > 0) {
      error = "Password must be at least 6 characters.";
    } else if (
      (name === "password" && value !== formData.confirmPassword) ||
      (name === "confirmPassword" && value !== formData.password)
    ) {
      error = "Passwords do not match.";
    } else if (name === "phoneNumber") {
      error = validatePhoneNumber(value);
    }

    setErrorMessage(error);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setErrorMessage("");
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateForm(name, value);
  };

  const handleTermsChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  /* 
  ================================
  SUBMISSION
  ================================
  */
  const disableSubmission =
    !formData.email ||
    !/\S+@\S+\.\S+/.test(formData.email) ||
    formData.email.endsWith("@") ||
    formData.password.length < 6 ||
    formData.password !== formData.confirmPassword ||
    !termsAccepted;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    // Prepare the form data for signup
    const uploadData = new FormData();
    uploadData.append("email", formData.email);
    uploadData.append("username", formData.username);
    uploadData.append("password", formData.password);
    uploadData.append("name", formData.fullName);
    uploadData.append("phone", formData.phoneNumber);

    try {
      // Attempt to sign up the user and recieve signup status
      const signUpStatus: SignupStatusCodes = await UserSignup(uploadData);

      // Checks if user was successfully created
      if (signUpStatus === SignupStatusCodes.CREATED) {
        console.log("user signup successful");

        // Attempt to log in the user after successful signup
        const loginStatus: LoginStatusCodes = await UserLogin(uploadData);

        if (loginStatus === LoginStatusCodes.SUCCESS){
        // If login was successful navigate to the phone verification page
          navigate(
            `/verify-phone?phone=${encodeURIComponent(formData.phoneNumber)}&action=account-verify`
          );
        }
      } else {
        // Get and display the appropriate error message
        const message = getSignupErrorMessage(signUpStatus);
        setErrorMessage(message);
      }

    } catch (error) {
      // Log any unexpected errors
      console.error("Error during signup: ", error);
      setErrorMessage(SignupMessage.UNEXPECTED_ERROR)
    }

    setIsLoading(false);
  };

  /* 
  ================================
  RENDERING
  ================================
  */

  return (
    <div>
      <Navbar />
      <div className="auth auth--signup">
        <h2 className="auth__title auth__title--signup">Register</h2>

        <form className="auth__form auth__form--signup">
          <div className="auth__inputs auth__inputs--signup">
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
              className="auth__input auth__input--signup"
              placeholder="Full Name"
            />

            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="auth__input auth__input--signup"
              placeholder="Email"
            />

            <PhoneInput
              international
              value={formData.phoneNumber}
              defaultCountry="US"
              onChange={(value: string | undefined) => {
                setFormData((prevState) => ({
                  ...prevState,
                  phoneNumber: value || "",
                }));
                validateForm("phoneNumber", value || "");
              }}
              required
              placeholder="Phone Number"
            />

            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              className="auth__input auth__input--signup"
              placeholder="Username"
            />

            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              className="auth__input auth__input--signup"
              placeholder="Password"
            />

            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
              className="auth__input auth__input--signup"
              placeholder="Confirm Password"
            />
          </div>

          <div className="auth__error-message auth__error-message--signup">
            {errorMessage && (
              <p className="auth__alert auth__alert--signup">
                <img
                  src={ErrorCircle}
                  alt="Error Icon"
                  className="auth__error-icon auth__error-icon--signup"
                />
                {errorMessage}
              </p>
            )}
          </div>

          <div className="signup__info">
            <div className="signup__terms-section">
              <input
                className="signup__agreement-checkbox"
                type="checkbox"
                checked={termsAccepted}
                onChange={handleTermsChange}
              />
              I have read the{" "}
              <a
                className="auth__action-link auth__action-link--signup auth__action-link--terms"
                href="/terms-of-service"
              >
                terms of service
              </a>
            </div>
          </div>

          <div className="auth__controls auth__controls--signup">
            {isLoading ? (
              <div className="loading-spinner loading-spinner--small">
                <Spinner />
              </div>
            ) : (
              <button
                onClick={handleSubmit}
                className={`auth__button auth__botton--signup ${
                  disableSubmission ? "auth__button--disabled" : ""
                }`}
                disabled={disableSubmission}
              >
                Continue
              </button>
            )}
          </div>
        </form>

        <div className="auth__links auth__links--signup">
          Already a user?{" "}
          <a
            href="/login"
            className="auth__action-link auth__action-link--signup"
          >
            Login instead.
          </a>
        </div>
      </div>
    </div>
  );
}

export default withRequirements(Signup, [notSignedInRequirement]);
