import React from "react";

import {
  SeverityLevel,
  SeverityLevelOrder,
} from "../../../../../../../../model/DisclosureAnalysis/Elements";
import {
  NHDAnalysis,
  NHDAnalysisItem,
} from "../../../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/NHD/NHD";
import { SelectedPage } from "../../../../PDFViewer/PDFViewer";

import "./NHD.css";

export default function NHDAnalysisViewer({
  analysis,
  setSelectedPage,
}: {
  analysis: NHDAnalysis;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}): JSX.Element {
  if (!analysis || !analysis.items) {
    return <div>Nothing to report.</div>;
  }
  return (
    <div>
      <div className="component-analysis-section">
        <div className="hoa-rental-restrictions-list">
          {analysis.items
            .sort(
              (a, b) =>
                SeverityLevelOrder[a.severity] - SeverityLevelOrder[b.severity]
            )
            .map((item: NHDAnalysisItem, index: number) => (
              <div
                key={index}
                className={`component-analysis-section-item ${
                  item.reference ? "has-reference" : ""
                }`}
                onClick={() => {
                  if (item.reference) {
                    setSelectedPage({
                      fileID: item.reference.pdf?.file_id,
                      page: item.reference.page,
                      region: item.reference.region,
                    } as SelectedPage);
                  }
                }}
              >
                <div className="component-analysis-section-item-description bold">
                  {item.item}
                </div>
                <div className="component-analysis-section-item-recommendation">
                  <b>Issue:</b> {item.issue}
                </div>
                {item.severity !== SeverityLevel.NOTAPPLICABLE && (
                  <div className={`severity-box ${item.severity}`}>
                    {item.severity}
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
