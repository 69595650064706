/* React */
import React from "react";
import { useNavigate } from "react-router-dom";

/* UI Components */
import { logoutCurrentUser } from "../../../../firebase/authentication/functions";

/* Styling */
import "./DashboardSideNav.css";
import { getWithAuth } from "../../../../firebase/authentication/auth";

const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;

const DashboardSideNav: React.FC = () => {
  const navigate = useNavigate();

  const goToSubscriptionManagementLink = async () => {
    const response = await getWithAuth(
      `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/subscription/create-customer-portal-session`
    );
    if (response.ok) {
      const { url } = await response.json();
      window.location.replace(url);
    } else if (response.status === 403) {
      navigate("/purchase");
    } else {
      throw new Error(`Error getting subscription.`);
    }
  };

  const handleLogoutButton = () => {
    logoutCurrentUser().then(() => {
      navigate("/");
    });
  };

  return (
    <div className="dashboard-side-nav">
      <div
        onClick={() => goToSubscriptionManagementLink()}
        className="dashboard-side-nav-route"
      >
        Subscription
      </div>
      <div
        onClick={() => handleLogoutButton()}
        className="dashboard-side-nav-route"
      >
        Logout
      </div>
    </div>
  );
};

export default DashboardSideNav;
