import React, { useState } from "react";

import {
  AnalysisAttributeCategory,
  SeverityLevel,
  SeverityLevelOrder,
} from "../../../../../../../../model/DisclosureAnalysis/Elements";
import arrowIcon from "../../../assets/SectionArrow.svg";
import "./TransferDisclosure.css";
import "../Components.css";
import { SelectedPage } from "../../../../PDFViewer/PDFViewer";
import {
  TransferDisclosureAnalysis,
  TransferDisclosureAnalysisAttribute,
} from "../../../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/TransferDisclosure/TransferDisclosureAnalysis";

export default function TransferDisclosureAnalysisViewer({
  analysis,
  setSelectedPage,
}: {
  analysis: TransferDisclosureAnalysis;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}): JSX.Element {
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({ "Red Flags": true, "Pertinent Info": false, Other: false });

  const [expandedItems, setExpandedItems] = useState<{
    [key: string]: boolean;
  }>({});

  if (!analysis || !analysis.items) {
    return <div>Nothing to report.</div>;
  }

  const toggleSection = (sectionName: string) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [sectionName]: !prevState[sectionName],
    }));
  };

  const toggleItem = (index: number) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const attributes = Object.keys(analysis)
    .filter((key) => key !== "analysis_sub_type")
    .map((key) => analysis[key as keyof TransferDisclosureAnalysis])
    .filter((attr) => Array.isArray(attr) && typeof attr[0] !== "string")
    .flat() as TransferDisclosureAnalysisAttribute[];

  const categorizedItems = attributes?.reduce<{
    redFlag: TransferDisclosureAnalysisAttribute[];
    pertinent: TransferDisclosureAnalysisAttribute[];
    other: TransferDisclosureAnalysisAttribute[];
  }>(
    (acc, item) => {
      switch (item.category) {
        case AnalysisAttributeCategory.REDFLAG:
          acc.redFlag.push(item);
          break;
        case AnalysisAttributeCategory.INFO:
          acc.pertinent.push(item);
          break;
        case AnalysisAttributeCategory.DUMP:
          acc.other.push(item);
          break;
        default:
          acc.other.push(item);
      }
      return acc;
    },
    { redFlag: [], pertinent: [], other: [] }
  );

  const renderItems = (items: TransferDisclosureAnalysisAttribute[]) =>
    items?.length > 0 && (
      <div className="component-analysis-section-list">
        {items
          .sort(
            (a, b) =>
              SeverityLevelOrder[a.severity || "MINOR"] -
              SeverityLevelOrder[b.severity || "MINOR"]
          )
          .map((item, index) => (
            <div
              key={index}
              className={`component-analysis-section-item ${
                item.reference ? "has-reference" : ""
              }`}
              onClick={() => {
                if (item.reference) {
                  setSelectedPage({
                    fileID: item.reference.pdf?.file_id,
                    page: item.reference.page,
                    region: item.reference.region,
                  } as SelectedPage);
                }
              }}
            >
              <div className="component-analysis-section-item-description bold">
                {item.description}
              </div>

              {/* Additional Info Toggle */}
              <div className="component-analysis-section-item-additional-info-wrapper">
                {item.additional_info && (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleItem(index);
                    }}
                  >
                    <span className="component-analysis-section-item-additional-info-toggle">
                      <img
                        src={arrowIcon}
                        alt="toggle"
                        className={`component-analysis-section-item-additional-info-arrow ${
                          expandedItems[index] ? "expanded" : "collapsed"
                        }`}
                      />
                    </span>
                    Additional Info
                  </span>
                )}

                {/* Additional Info Display */}
                {expandedItems[index] && item.additional_info && (
                  <div
                    className={`component-analysis-section-item-additional-info ${
                      item.reference ? "has-reference" : ""
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (item.additional_info_reference) {
                        console.log(item.additional_info_reference.page);
                        console.log(item.additional_info_reference.region);
                        setSelectedPage({
                          fileID: item.additional_info_reference.pdf?.file_id,
                          page: item.additional_info_reference.page,
                          region: item.additional_info_reference.region,
                        } as SelectedPage);
                      }
                    }}
                  >
                    {item.additional_info}
                  </div>
                )}
              </div>
              {item.severity !== SeverityLevel.NOTAPPLICABLE &&
                item.severity !== SeverityLevel.NONE && (
                  <div className={`severity-box ${item.severity}`}>
                    {item.severity}
                  </div>
                )}
            </div>
          ))}
      </div>
    );

  const renderSection = (
    title: string,
    items: TransferDisclosureAnalysisAttribute[]
  ) =>
    items.length > 0 && (
      <div className="component-analysis-section">
        <div
          className="component-analysis-section-header"
          onClick={() => toggleSection(title)}
        >
          <div>
            <img
              src={arrowIcon}
              alt="toggle"
              className={`component-analysis-section-header-arrow ${
                expandedSections[title] ? "expanded" : "collapsed"
              }`}
            />
            {title}
          </div>
          <div className="component-analysis-section-header-brief">
            {items.length}
          </div>
        </div>
        {expandedSections[title] && renderItems(items)}
      </div>
    );

  return (
    <div>
      {renderSection("Red Flags", categorizedItems.redFlag)}
      {renderSection("Pertinent Info", categorizedItems.pertinent)}
      {renderSection("Other", categorizedItems.other)}
    </div>
  );
}
