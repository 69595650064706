/* React */
import React, { useEffect, useRef, useState } from "react";

/* Data Models */
import { chatSuggestionsByType } from "../../../../../../model/DisclosureAnalysis/Chat/ChatSuggestions";
import { FirestoreChatRecord } from "../../../../../../model/DisclosureAnalysis/Chat/Chat";
import { Reference } from "../../../../../../model/DisclosureAnalysis/Reference/Reference";
import ComponentAnalysisType from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/Types";

/* UI Components */
import ChatHistory from "./ChatHistory";
import ChatInput from "./ChatInput";
import ChatSuggestions from "./ChatSuggestions";

/* Styling */
import arrowIcon from "../assets/ChatArrow.svg";
import "./Chat.css";

interface ChatViewProps {
  chatRecord: FirestoreChatRecord;
  onSendMessage: (message: string) => void;
  onReferenceClick: (reference: Reference) => void;
  componentType: ComponentAnalysisType;
  isTyping: boolean;
}

const ChatView: React.FC<ChatViewProps> = ({
  chatRecord,
  onSendMessage,
  onReferenceClick,
  componentType,
  isTyping,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLong, setIsLong] = useState(false);
  const chatViewRef = useRef<HTMLDivElement>(null);
  const isChatEmpty = chatRecord.messages.length === 0;

  const toggleChat = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    // Create a MutationObserver to monitor class changes on the disclosure-viewer-navigation element
    const disclosureViewer = document.querySelector(
      ".disclosure-viewer-navigation"
    );
    const observer = new MutationObserver(() => {
      if (disclosureViewer?.classList.contains("expanded")) {
        setIsLong(true);
      } else {
        setIsLong(false);
      }
    });

    if (disclosureViewer) {
      observer.observe(disclosureViewer, {
        attributes: true,
        attributeFilter: ["class"],
      });
    }

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []); // Empty dependency array ensures this effect runs once on mount

  useEffect(() => {
    if (isExpanded && chatViewRef.current) {
      chatViewRef.current.scrollTop = chatViewRef.current.scrollHeight;
    }
  }, [isExpanded, isTyping, chatRecord.messages]);

  const handleSendMessage = async (message: string) => {
    await onSendMessage(message);
  };

  const suggestions = chatSuggestionsByType[componentType] || [
    "What are the main issues in this report?",
    "Summarize the key findings",
    "Are there any red flags I should be aware of?",
  ];

  return (
    <div
      className={`chat-container ${isExpanded ? "expanded" : "collapsed"} ${
        isLong ? "long" : ""
      }`}
    >
      <div
        className={`chat-toggle ${isExpanded ? "expanded" : "collapsed"}`}
        onClick={toggleChat}
      >
        <img
          src={arrowIcon}
          alt="toggle"
          className={`chat-toggle-arrow ${
            isExpanded ? "expanded" : "collapsed"
          }`}
        />
        <span>Document Chat</span>
      </div>
      {isExpanded && (
        <div className="chat-view" ref={chatViewRef}>
          <ChatHistory
            messages={chatRecord.messages}
            onReferenceClick={onReferenceClick}
            isTyping={isTyping}
          />
          <div className="chat-input-area">
            {isChatEmpty && (
              <ChatSuggestions
                suggestions={suggestions}
                onSuggestionClick={handleSendMessage}
              />
            )}
            <ChatInput onSendMessage={handleSendMessage} isTyping={isTyping}/>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatView;
